<template>
  <div class="home">
      <div class="centerx">
        <vs-popup classContent="popup-example"  :title="`${$t('dashboard.expire-card.popup.heading1')} > `+user.organisation" :active.sync="popupActivo2">
            <div class="popup-from expire mp-3">
                <div class="expire-container gray-border">
                    <div class="con1">
                        <div class="inputs">
                            <vs-input class="inputx simple-input" :label-placeholder="$t('dashboard.expire-card.popup.card-id')" v-model="data.card_no"/>
                            <vs-input class="inputx simple-input" :label-placeholder="$t('dashboard.expire-card.popup.first-name')" v-model="data.first_name"/>
                            <vs-input class="inputx simple-input" :label-placeholder="$t('dashboard.expire-card.popup.last-name')" v-model="data.last_name"/>
                            <vs-input class="inputx simple-input" :label-placeholder="$t('dashboard.expire-card.popup.date-birth')" v-model="data.date_of_birth"/>
                            <vs-input class="inputx simple-input" :label-placeholder="$t('dashboard.expire-card.popup.function')" v-model="data.function"/>
                            <vs-input class="inputx spec-input" :label-placeholder="$t('dashboard.expire-card.popup.driver-license')" v-model="data.driver_licence"/>
                            <vs-input class="inputx simple-input" :label-placeholder="$t('dashboard.expire-card.popup.department')" v-model="data.department"/>
                        </div>
                        <div class="upload-img">
                            <div class="img-container">
                                <img src="/icons/upload-photo.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="con2">
                        <vs-input class="inputx simple-input mb-4" label-placeholder="Status" v-model="data2.card_no"/>
                        <vs-input class="inputx spec-input mb-4" :label-placeholder="$t('dashboard.expire-card.popup.created-at')" v-model="data2.created_on"/>
                        <vs-input class="inputx spec-input mb-4" :label-placeholder="$t('dashboard.expire-card.popup.approved-at')" v-model="data2.approved_on"/>
                        <vs-input class="inputx spec-input mb-4" :label-placeholder="$t('dashboard.expire-card.popup.valid-until')" v-model="data2.date_of_expiry"/>
                        <vs-input class="inputx spec-input mb-4" :label-placeholder="$t('dashboard.expire-card.popup.locked-at')" v-model="data2.locked_on"/>
                    </div>
                </div>
                <div class="popup-bottom ">
                    <vs-button class="pop-btn" color="primary" type="filled" icon="done">{{$t('dashboard.expire-card.popup.save')}}</vs-button>
                    <vs-button class="pop-btn" color="primary" type="filled" icon="done">{{$t('dashboard.expire-card.popup.cancel')}}</vs-button>
                </div>
            </div>
        </vs-popup>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    props:['user'],
    data(){
        return {
            manfred:"",
            mustermann:"",
            ip:'',
            commander:"",
            letters:"",
            dorf:"",
            data:{
                card_no:"",
                first_name:'',
                date_of_birth:'',
                function:'',
                driver_licence:'',
                last_name:'',
                department:''
            },
            data2:{
                status:'',
                created_on:'',
                approved_on:'',
                date_of_expiry:'',
                locked_on:''
            },
            popupActivo2:false,
        }
    },
    methods:{
        open(){
            this.popupActivo2=true;
        }
    }

});
</script>
<style >
</style>