<template>
  <div class="amendments-container" v-if="idCards">
    <h1 style="margin-left: 20px;">{{$t('dashboard.expire-card.table.heading1')}}</h1>
    <div class="idcard-con">
      <div class="con mt-4">
        <vs-row class="mt-3">
          <vs-input class="search-input org-width" icon-after :placeholder="$t('dashboard.expire-card.table.name-search')" v-model="searchUser" icon="search" />
          <vs-input type="date" class="ml-3" icon="search" icon-after v-model="searchExpireDate" />
        </vs-row>
        <div class="outer-con2">
          <div class="table-container" style="overflow-y: auto; max-width: 100%;">
            <table>
              <thead>
                <tr>
                  <th @click="sort('valid_until')">{{$t("dashboard.expire-card.table.valid-until")}}<div class="arrow" v-if="'valid_until' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                  <th @click="sort('organisation_name')">{{$t("dashboard.expire-card.table.organization")}}<div class="arrow" v-if="'organisation_name' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                  <th @click="sort('person_last_name')">{{$t("Name")}}<div class="arrow" v-if="'person_last_name' == currentSort" v-bind:class="ascending ? 'arrow_up' : 'arrow_down'"></div></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="idCard in idCards" :key="idCard.id" @click="openIdCard(idCard)">
                  <td>{{ idCard.valid_until | moment("DD.MM.YYYY") }} </td>
                  <td>{{idCard.organisation_name}}</td>
                  <td>{{idCard.person_last_name}}, {{idCard.person_first_name}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <PopupIDcard ref="popup" :organization="selectedOrganization" />

    <!-- DEBUG MODE -->
    <div v-if="isDebugMode">
      <h1>customerConfiguration</h1>
      {{customerConfiguration}}

      <h1>idCards</h1>
      {{idCards}}

      <h1>user</h1>
      {{user}}

    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Popup from '../../components/dashboard/PopupExpiryDate.vue'

import PopupIDcard from '@/components/id-card/PopupIDcard.vue'

import moment from 'moment'



export default Vue.extend({
  name: 'ExpiredIDCards',
  components:{
      PopupIDcard
  },

  async mounted() {
    await this.$store.dispatch('idCard/loadIdCards',  { status: "expired"})
    // await this.$store.dispatch('customer/loadCustomer', this.user.customer_id)
    await this.$store.dispatch('customer/loadCustomerConfiguration', this.user.customer_id)
  },

  data(){
    return {
      selectedOrganization: {},
      searchExpireDate :"",
      searchUser:'',
      prop_user:[],
      ascending: true,
      currentSort:'valid_until',
      currentSortDir:'desc'
    }
  },
  computed:{
    idCards:function() {
      // return this.$store.getters['idCard/getIdCards']
      const idCards = this.$store.getters["idCard/getIdCards"];
      idCards.sort((a,b)=> {
          let modifier=1;
          if(this.currentSortDir === 'desc') modifier = -1;
          if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
          if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
          return 0;
      });
      return idCards.filter((card) => {
          if (this.searchUser != "") {
            const name = card.person_first_name + " " + card.person_last_name;
            const searchMatch = name.toLowerCase().includes(this.search.toLowerCase());
            if (searchMatch) {
              return true;
            }
            return false;
          } else if (this.searchExpireDate !== "") {
              const validdate = card.valid_until
              const searchValiddate = validdate.toLowerCase().includes(this.searchExpireDate)
              if (searchValiddate) {
                return true;
              }
              return false
          } else {
            return card.status == "expired";
          }
      });
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    customer() {
      return this.$store.getters['customer/getCustomer']
    },
    customerConfiguration() {
      return this.$store.getters['customer/getCustomerConfiguration']
    },
    // getExpiredIdCards(){
    //   // const expiry_warning_days = 909 //30 // 909
    //   const expiry_warning_days = this.customerConfiguration.expiry_warning_days
    //   const idCards = this.$store.getters['idCard/getIdCards']
    //   const expiredIdCards = idCards.filter(card => {
    //     const valid_until = Math.floor(Date.parse(card.valid_until) / 1000)
    //     const now = Math.floor((new Date()).getTime() / 1000)

    //     // Delta in days
    //     const delta = (expiry_warning_days * (3600*24))
    //     if (valid_until < now+delta) {
    //       return true
    //     }
    //     return false
    //   });


    //   if (this.searchUser !== "") {
    //     return expiredIdCards.filter(card => {
    //       const name = (card.person_first_name + card.person_last_name).toLowerCase()
    //       if (name.toLowerCase().includes(this.searchUser)) {
    //         return true;
    //       }
    //       return false
    //     });

    //   } else if (this.searchExpireDate !== "") {
    //     return expiredIdCards.filter(card => {
    //       if (card.valid_until.toLowerCase().includes(this.searchExpireDate)) {
    //         return true;
    //       }
    //       return false
    //     });

    //   } else {
    //     return expiredIdCards
    //   }
    // }



},


  methods:{
      sort:function(s) {
        if(s === this.currentSort) {
          this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
          this.ascending = !this.ascending;
        }
        this.currentSort = s;
      },
      async openIdCard(idCard) {
        // await this.$store.dispatch('person/loadPerson', idCard.person_id)
        // const person =  this.$store.getters['person/getPerson']
        // await this.$store.dispatch('organization/loadOrganization', person.organisation_id)

        // this.selectedOrganization = this.$store.getters['organization/getOrganization']

        const popup=this.$refs.popup ;
        popup.openEdit(idCard);
      },
  },


});
</script>
<style>
.vuesax-app-is-ltr .vs-input--input.icon-after-input {
  padding-right: 2.5rem !important;
}
</style>
